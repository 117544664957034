<script>
import Layout from "@/views/pages/employee/layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import notification from "@/components/notifications/notification";
export default {
  page: {
    title: "Team",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,notification
  },

 data() {
    return {
       items: [
        {
          text: "Notifications",
        },
        {
          text: "All",
          active: true,
        },
      ],
      notifications:[],

  }
 },
  

 mounted(){
   
  },

methods:{
 
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
          <notification></notification>
    
  </Layout>
</template>
